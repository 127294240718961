import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react';
import * as ReactDOM from 'react-dom/client';

const browserHydrateFunction = () => {
  return (element: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined, container: Element | DocumentFragment) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export default browserHydrateFunction;